import React from 'react';
import { Link } from 'gatsby';
import './footer.css';
import { consentContext } from '../../context/ConsentContext';

export default function Footer() {
  return (
    <consentContext.Consumer>
      {(context) => (
        <React.Fragment>
          <footer className="footer">
            <nav className="footer-nav">
              <Link to="/impressum">Impressum | </Link>
              <Link to="/datenschutz">Datenschutz | </Link>
              <a
                className={'footer-cookie-link'}
                onClick={context.toggleModalVisibility}
              >
                Cookie-Einstellungen
              </a>
            </nav>
          </footer>
        </React.Fragment>
      )}
    </consentContext.Consumer>
  );
}
