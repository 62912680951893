import Helmet from 'react-helmet';
import React from 'react';

import { useState, useEffect } from 'react';
const defaultDescription =
  'Internationale Stiftung zur Foerderung von Kultur und Zivilisation';

type Props = {
  title: string;
  description?: string;
};

function getWindowDimensions() {
  let width = 1610;
  let height = 0;
  if (typeof window !== 'undefined') {
    // Safari 9 /10 have outerWidth set to 0 and the correct value in innerWidth
    // other browsers have the correct value in outerWidth
    width = window.outerWidth || window.innerWidth;
    height = window.outerHeight || window.innerHeight;
  }

  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      console.log('handle resize');
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export default function TitleMetaTags({
  title,
  description,
}: Props): JSX.Element {
  const { width } = useWindowDimensions();

  let elem: JSX.Element = (
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, shrink-to-fit=no"
    />
  );
  // remove previous meta tag for viewport
  if (typeof document !== 'undefined') {
    const allMeta = document.getElementsByTagName('meta');
    if (allMeta) {
      const viewPort = allMeta['viewport'];
      if (viewPort) {
        if (
          viewPort.getAttribute('content').startsWith('width=device-width') &&
          width >= 1024
        ) {
          document.head.removeChild(viewPort);
        }
      }
    }
  }
  if (width >= 1024) {
    elem = (
      <meta
        name="viewport"
        content="width=1610, initial-scale=0.618, shrink-to-fit=no, maximum-scale=0.618, minimum-scale=0.618"
      />
    );
  } else if (width <= 400) {
    elem = (
      <meta
        name="viewport"
        content="width=400, initial-scale=1.0, shrink-to-fit=no, maximum-scale=1.0, minimum-scale=1.0"
      />
    );
  }

  return (
    <Helmet
      title={title}
      htmlAttributes={{
        lang: 'de',
      }}
    >
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />

      <meta
        property="og:description"
        content={description || defaultDescription}
      />
      {elem}
    </Helmet>
  );
}
